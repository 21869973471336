import React from "react";
import Header from "@layouts/Header";
import Image from "@components/Image";
import Post from "@components/Post";
import Author from "./components/Author";
import { HTMLContent } from "@components/Content";
import ReactMarkdown from "react-markdown";

import FacebookIcon from "@components/svg/FacebookIcon";
import LinkedinIcon from "@components/svg/LinkedinIcon";
import TwitterIcon from "@components/svg/TwitterIcon";

import "./BlogPost.styles.scss";

const BlogPostTemplate = (data) => {
  const post = data?.post;
  let isPreview = data.isPreview ? true : false;
  let frontmatter = isPreview ? data.data : post?.frontmatter;
  const author = data?.author;
  const categoriesPosts = data?.categoriesPosts;
  const html = isPreview ? data.data.body : post?.html ;

  return (
    <div className="blog-post">
      <Header title={frontmatter?.title} video={frontmatter?.video} />
      <div className="blog-post__wrapper container">
        <div className="blog-post__image">
          <Image data={frontmatter?.featuredimage} />
        </div>
        <div className="blog-post__content-container">
          <div className="blog-post__header">
            {author && author.frontmatter && (
              <Author data={{ ...(author.frontmatter || {}) }} />
            )}
            <div className="blog-post__date">{isPreview ? frontmatter?.date?.toDateString() : frontmatter?.date}</div>
          </div>
          <div className="blog-post__content">
            {isPreview && html ?
              <ReactMarkdown source={html} />
              : <HTMLContent content={html} />
            }
            <div className="blog-post__share">
              <div className="blog-post__share__title">
                Share this story
              </div>
              <div className="blog-post__share__items">
                <a>
                  <LinkedinIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        {categoriesPosts && categoriesPosts.length > 0 && (
          <div className="blog-post__related-posts">
            <h3 className="blog-post__related-posts__title">
              Related issue
            </h3>
            <div className="blog-post__related-posts__items">
              {categoriesPosts?.map((item) => {
                return (
                  <Post
                    slug={item?.fields?.slug}
                    {...item.frontmatter}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPostTemplate;
