import React from "react";
import Image from "@components/Image";
import "./Author.styles.scss";

const Author = ({ data }) => {
  return (
    <div className="author">
      <div className="author__image">
        <Image data={data?.image} />
      </div>
      <div className="author__details">
        <div className="author__title">{data?.fullname}</div>
        <div className="author__role">{data?.role}</div>
      </div>
    </div>
  );
};

export default Author;
