import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        fill="#1E1B18"
        d="M16 0C7.165 0 0 7.165 0 16s7.165 16 16 16 16-7.165 16-16S24.835 0 16 0zm-4.65 22.24a1.948 1.948 0 11-3.896 0v-7.827a1.948 1.948 0 113.897 0v7.826zM9.403 10.862h-.025c-1.308 0-2.153-.9-2.153-2.025 0-1.15.871-2.025 2.204-2.025 1.333 0 2.153.875 2.179 2.025 0 1.125-.846 2.025-2.205 2.025zm16 11.376a1.948 1.948 0 01-3.897 0v-4.323c0-1.576-.564-2.651-1.974-2.651-1.076 0-1.717.725-2 1.425-.102.25-.127.6-.127.95v4.604a1.944 1.944 0 11-3.889-.006c.008-2.147.02-5.585.013-7.784-.003-1.085.887-1.99 1.973-1.99h1.524c.209 0 .379.17.379.379 0 .377.534.55.815.298.579-.52 1.42-.952 2.696-.952 2.564 0 4.486 1.675 4.486 5.276v4.774z"
      ></path>
    </svg>
  );
}

export default Icon;
