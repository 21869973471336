import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        fill="#1E1B18"
        d="M16 0C7.178 0 0 7.178 0 16s7.178 16 16 16 16-7.178 16-16S24.823 0 16 0zm7.425 12.12a.73.73 0 00-.277.578v.118c0 4.867-3.703 10.476-10.478 10.476-1.765 0-3.427-.437-4.885-1.209-.19-.1-.097-.392.118-.392.707 0 1.39-.099 2.038-.284.921-.262.642-1.715-.028-2.4a3.693 3.693 0 01-.602-.812c-.15-.275.104-.572.418-.572.245 0 .264-.338.045-.448a3.683 3.683 0 01-2.028-3.292c0-.02.023-.034.041-.024.148.08.302.15.461.21.322.12.507-.31.297-.583a3.672 3.672 0 01-.769-2.253c0-.939 1.14-1.076 1.856-.47a10.435 10.435 0 005.39 2.39c.42.055.748-.34.748-.763a3.683 3.683 0 016.054-2.82c.207.175.478.271.74.203.366-.094.72-.216 1.06-.362.308-.132.627.149.446.43a3.705 3.705 0 01-.256.35c-.185.225.015.647.294.564.26-.078.477.217.3.422a7.4 7.4 0 01-.983.944z"
      ></path>
    </svg>
  );
}

export default Icon;
