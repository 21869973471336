import React from "react";
import classNames from "classnames";
import "./Category.styles.scss";

const Category = ({ title, isWhite }) => {
  return (
    <span className={classNames("category", { "category--white": isWhite })}>
      {title}
    </span>
  );
};

export default Category;
