import React from "react";
import { Link } from "gatsby";
import Category from "@components/Category";
import Image from "@components/Image";
import "./Post.styles.scss";

const Post = ({ title, date, slug, featuredimage: image }) => {
  return (
    <div className="post">
      <div className="post__inner">
        <div className="post__image">
          <Link to={slug}>
            <Image data={image} />
          </Link>
        </div>
        <div className="post__details">
          <div className="post__category">
            <Category title="Category 1" />
          </div>
          <div className="post__title">
            <Link to={slug}>{title}</Link>
          </div>
          <div className="post__date">{date}</div>
        </div>
      </div>
    </div>
  );
};

export default Post;
